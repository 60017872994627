<template lang="html">
  <!-- ToTest remove this modal class -->
  <div class="ui tiny share modal" style="text-align:center;">
    <div v-if='twitterAuthStatus' class="ui red inverted basic segment">
      <p v-if="vid==='' && twitterAuthStatus===200">Please try again <i class="refresh icon" @click="newVideoURL(0)"></i></p>
      <p v-else-if="twitterAuthStatus===200">Please try again <i class="refresh icon" @click="getVideoURL(currentBackground)"></i></p>
      <p v-else>
        <span v-if="vid===''">Please try again <i class="refresh icon" @click="newVideoURL(0)"></i></span>
        <span v-else>Please try again <i class="refresh icon" @click="getVideoURL(currentBackground)"></i></span>
      </p>
    </div>
    <div v-else-if="tweetStatus=='Tweeted'" class="ui green inverted basic segment">
      <p>Your tweet has been sent</p>
    </div>
    <h5 class="ui center aligned header">
      <i class="fa fa-video-camera" style="margin-right:0px;"></i>
      Share as Video
    </h5>
    <div class="ui content" style="padding:0px;">
      <div v-if="url!='' && url!='err'" style="width:540px;height:303.75px;" class="flex flex-col items-center">
        <img v-show="loading" src="../assets/images/Ellipsis.svg" alt="" style="margin-top:120px;margin-bottom:120px;">
        <video v-show="!loading" ref="video" width="100%" height="100%" @loadedmetadata="loadedMetadata" @pause="pause">
          <source :src="url" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <span v-else-if="url=='err'" style="height:303.75px;">
        <img src="../assets/images/cat.png" style="margin-top:46px;margin-bottom:46px;">
      </span>
      <div v-else style="width:100%;height:303.75px;" class="flex justify-center">
        <img src="../assets/images/Ellipsis.svg" alt="" style="margin-top:120px;margin-bottom:120px;">
      </div>
      <!-- show all themes -->
      <div v-if="vid!==''" style="height:30px;padding:10px;">
        <span style="float:left;margin-left:5%;width:33%;">
          <img :key="i" v-for="(theme,i) in themes" :src="'https://res.cloudinary.com/di4gym80v/image/upload/c_scale,h_54,w_96/'+theme.imageid+'.jpg'" :class="[i==currentBackground?'active':'','imageCube']" @click="getVideoURL(i)">
        </span>
        <span style="float:right;margin-right:5%;">
          <button class="circular ui icon button playColor" @click="toggleState" :title="[state==='Pause'?'Play':'Pause']">
              <i :class="[state==='Pause'?'play':'pause','whiteText icon']"></i>
          </button>
          <button class="circular ui icon button playColor" @click="downloadVideo" title="Download Video">
              <i class="download whiteText icon"></i>
          </button>
        </span>
      </div>
    </div>
    <!-- <div v-if="vid!==''" class="actions" style="clear:both;text-align:center;">
      <button v-if="credential.twitter==undefined" class="ui button playColor" @click="addTwitterCredential" style="text-align:center;">
        Add Twitter
      </button>
      <div v-else-if="tweetStatus=='tweeting'">
        <img src="../assets/images/Ellipsis.svg" style="vertical-align:middle;">
      </div>
      <div v-else-if="url!='' && url!='err' && tweetStatus!='tweeting' && !loading">
        <div class="ui transparent input borderAll" style="width:90%;height:60px;">
          <textarea id="textarea" @keyup="countChar" type="text" placeholder="Enter tweet content here." v-model="message" style="color: #ffffff;resize:none;outline:none;background:transparent;width:100%;padding:5px;line-height:1.5em;">
          </textarea>
        </div>
        <div :class="[actualCount>=0?'tealText':'redText']" style='width:95%;text-align:right;margin-top:5px;'>
          {{actualCount}}
        </div>
        <button v-if="actualCount>=0" class="ui button playColor" @click="tweet" style="display:block;margin:auto;margin-top:10px;">
          Tweet
        </button>
        <button v-else class="ui button playColor disabled" style="display:block;margin:auto;margin-top:10px;">
          Tweet
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
// This component is for share as video on twitter etc
import axios from 'axios'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import CONSTANTS from '@/constants/CONSTANTS'
import store from '@/services/store'
const uuidv1 = require('uuid/v1')
export default {
  name: 'share',
  data () {
    return {
      credential: {},
      url: '',
      tweetStatus: '',
      twitterAuthStatus: '',
      state: 'Pause',
      message: 'via @spext_app!',
      actualCount: '125',
      loading: true,
      start: 0,
      end: 0,
      words: [],
      currentBackground: 0,
      // information about all the themes
      themes: [
        { 'imageid': 'spext_bg7', 'font': 'tangerine-regular', 'font_size': 72, 'font_color': '#08234E' },
        { 'imageid': 'spext_bg8', 'font': 'pacifico-regular', 'font_size': 50, 'font_color': 'white' },
        { 'imageid': 'spext_bg9', 'font': 'karla-regular', 'font_size': 40, 'font_color': 'black' },
        { 'imageid': 'spext_bg11', 'font': 'merienda', 'font_size': 40, 'font_color': 'black' },
        { 'imageid': 'spext_bg14', 'font': 'tangerine-regular', 'font_size': 72, 'font_color': '#0c7191' },
        { 'imageid': 'spext_bg15', 'font': 'lato-regular', 'font_size': 40, 'font_color': '#d4d4d4' },
        { 'imageid': 'spext_bg16', 'font': 'acme-regular', 'font_size': 40, 'font_color': '#B7E702' },
        { 'imageid': 'spext_bg17', 'font': 'cabin-sketch-regular', 'font_size': 46, 'font_color': 'white' },
        { 'imageid': 'spext_bg18', 'font': 'sacramento', 'font_size': 76, 'font_color': '#002266' },
        { 'imageid': 'spext_bg19', 'font': 'sacramento', 'font_size': 72, 'font_color': '#00a0d6' },
        { 'imageid': 'spext_bg21', 'font': 'amita-regular', 'font_size': 45, 'font_color': '#037FF5' },
        { 'imageid': 'spext_bg24', 'font': 'tangerine-regular', 'font_size': 72, 'font_color': 'white' }
      ],
      generatedVideos: {},
      vid: '',
      currentTheme: '',
      currentRequest: ''
    }
  },
  mounted () {
    // console.log('mounted share as video')
  },
  beforeDestroy () {
    // console.log('destroying share as video')
  },
  computed: {
    shareJSON () {
      return store.state.modal.shareJSON
    },
    uid () {
      return store.state.app.user.uid
    },
    podId () {
      return this.$route.params.podId
    }
  },
  watch: {
    shareJSON (val) {
      this.words = val
      this.init()
      this.newVideoURL(0)
    }
  },
  methods: {
    init () {
      console.log('init called')
      var vm = this
      vm.currentRequest = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      vm.getSnippets()
    },
    cleanUp () {
      console.log('cleanup called')
      let vm = this
      firebase.database().ref(vm.uid + '/podcast-info/' + vm.podId + '/exported_files/snippet/' + vm.currentRequest).off()
    },
    getSnippets () {
      // video creation is async so listen on firebase and show
      var vm = this
      // console.log('getsnipets', vm.uid, vm.podId, vm.currentRequest)
      firebase.database().ref(vm.uid + '/podcast-info/' + vm.podId + '/exported_files/snippet/' + vm.currentRequest).on('value', function (snapshot) {
        // console.log('val is', snapshot)
        vm.generatedVideos = {}
        snapshot.forEach(function (childSnapshot) {
          // console.log('childval', childSnapshot.val())
          vm.$set(vm.generatedVideos, childSnapshot.key, childSnapshot.val())
          if (childSnapshot.key === vm.currentTheme && childSnapshot.val()['url']) {
            // console.log('video generated', childSnapshot.val()['url'])
            vm.setVideoURL(childSnapshot.val()['url'])
          }
        })
      })
    },
    downloadVideo () {
      var a = document.createElement('a')
      a.href = this.url
      a.download = 'Spext.mp4'
      a.target = '_blank'
      a.click()
    },
    countChar () {
      var textLength = $('#textarea').val().length
      this.actualCount = 280 - textLength
    },
    toggleState: function () {
      console.log('Toggling State')
      if (this.state === 'Pause') {
        this.state = 'Play'
        this.$refs.video.play()
      } else {
        this.state = 'Pause'
        this.$refs.video.pause()
      }
    },
    pause: function () {
      console.log('pause')
      this.state = 'Pause'
    },
    loadedMetadata () {
      console.log('loadedMetadata')
      this.loading = false
      $('.ui.share.modal').modal('refresh')
    },
    isValidUrl (string) {
      var vm = this
      try {
        /* eslint-disable no-new */
        new URL(string)
        return true
      } catch (_) {
        console.log('Invalid URL')
        vm.loading = false
        vm.twitterAuthStatus = 200
        return false
      }
    },
    tweet () {
      // tweet the link
      var vm = this
      // vm.url = 'http://res.cloudinary.com/di4gym80v/video/upload/g_north_west,h_540,l_image:spext_bg3,w_960/b_rgb:00000000,co_rgb:041D4A,l_subtitles:quicksand_25:texz8bs9jlxma1qgct94.srt/xuueq5u85vtvnsdjjqpb.mp4'
      if (!vm.isValidUrl(vm.url)) {
        vm.$parent.$refs.toastr.Add({
          msg: 'Video link is invalid, cant tweet',
          position: 'toast-bottom-left',
          type: 'error',
          progressBar: true,
          preventDuplicates: true
        })
        return
      }
      vm.tweetStatus = 'tweeting'
      console.log('accessToken', vm.credential.twitter.accessToken)
      console.log('secret', vm.credential.twitter.secret)
      console.log('message', vm.message)
      console.log('url', vm.url)
      axios.post(CONSTANTS.TWEET_URL, // call backend here for tweet video upload
        {
          'access_token': vm.credential.twitter.accessToken,
          'access_token_secret': vm.credential.twitter.secret,
          'tweet': vm.message,
          'podid': vm.$parent.podId,
          'userid': vm.$parent.user.uid
        }
      ).then(function (response) {
        console.log(response)
        if (response.status === 200 && response.data.error === undefined) {
          console.log('Tweeted')
          vm.tweetStatus = 'Tweeted'
          setTimeout(function () {
            vm.tweetStatus = ''
            $('.ui.share.modal').modal('refresh')
            vm.$refs.video.load()
          }, 5000)
        } else {
          throw new Error(response.data.error)
        }
      })
        .catch(function (error) {
          vm.tweetStatus = ''
          vm.twitterAuthStatus = 200
          console.log(error)
        })
    },
    addTwitterCredential () {
      // take the current user, and link it with its twitter account
      var vm = this
      var provider = new firebase.auth.TwitterAuthProvider()
      console.log('addTwitterCredential')
      firebase.auth().currentUser.linkWithPopup(provider)
        .then(function (result) {
          console.log(result)
          let update = {}
          let provider = {}
          if (result.credential) {
            provider = result.credential.providerId.replace('.com', '')
          }
          update[provider] = {}
          update[provider]['accessToken'] = result.credential.accessToken // this info is received only once
          update[provider]['secret'] = result.credential.secret
          vm.$set(vm.credential, provider, update[provider])
          console.log(update)
          vm.twitterAuthStatus = ''
          // store it in db
          return firebase.database().ref(vm.$parent.user.uid + '/userInfo/other/credential').update(update)
        }).then(function (res) {
          console.log(res)
          console.log('written credential to firebae')
        }).catch(function (error) {
          if (error.code === 'auth/provider-already-linked') {
            firebase.auth().currentUser.unlink('twitter.com')
            vm.addTwitterCredential()
          }
          vm.twitterAuthStatus = error.message
          console.log(error)
          console.log(error.code)
          console.log(error.message)
        })
    },
    requestBackend (url, postCall, themeId) {
      let vm = this
      vm.generatedVideos[themeId] = {} // cache generated videos in this dict
      vm.generatedVideos[themeId]['status'] = 'start'
      axios.post(url, postCall).then(function (response) {
        console.log(response.data)
        if (response.status === 200) {
          console.log('response from backend', response.data)
          // vm.setVideoURL(response.data.uri, background, response.data.vid)
        }
      })
        .catch(function (error) {
          vm.generatedVideos[themeId] = {}
          vm.generatedVideos[themeId]['status'] = 'error'
          if (themeId === vm.currentBackground) {
            vm.url = 'err'
            if (vm.twitterAuthStatus === '') {
              vm.twitterAuthStatus = error
            }
            setTimeout(function () {
              $('.ui.share.modal').modal('refresh')
            }, 10)
          }
          console.log(error)
        })
    },
    getVideoURL (background) {
      /**
       * if the video is not cached, it will request the backend to generate the video.
       */
      console.log('getVideoURL called', background)
      var vm = this
      vm.currentBackground = background
      vm.twitterAuthStatus = ''
      vm.url = ''
      var theme = ''
      theme = vm.themes[background]
      vm.currentTheme = theme.imageid
      vm.state = 'Pause'
      vm.loading = true
      if (vm.generatedVideos[vm.currentTheme]) {
        if (vm.generatedVideos[vm.currentTheme]['status'] === 'done') {
          vm.url = vm.generatedVideos[vm.currentTheme]['url']
          vm.$refs.video.load()
        }
        return
      }
      vm.generatedVideos[background] = 'getting'
      // if (vm.$parent.podcastList[vm.$parent.podId]['language'] && vm.$parent.podcastList[vm.$parent.podId]['language'] !== 'en-US') {
      //   console.log(vm.$parent.podcastList[vm.$parent.podId]['language'])
      //   theme['font'] = 'noto-sans'
      // }
      // theme['font'] = 'noto-sans'

      axios.defaults.headers.post['Content-Type'] = 'application/json'
      var postCall = {}
      postCall['userid'] = vm.uid
      postCall['vid'] = vm.vid
      postCall['theme'] = theme
      postCall['start'] = vm.words[0][1]
      postCall['end'] = vm.words[vm.words.length - 1][2]
      postCall['podid'] = vm.podId
      postCall['uid'] = vm.currentRequest
      console.log(postCall)
      vm.requestBackend(CONSTANTS.VIDEO_GENERATE_URL, postCall, theme.imageid) // call backend here
    },
    setVideoURL (url) {
      console.log('setvideourl', url)
      var vm = this
      if (vm.isValidUrl(url)) {
        vm.url = url
        vm.vid = vm.currentRequest + vm.currentTheme
        setTimeout(function () {
          $('.ui.share.modal').modal('refresh')
          vm.$refs.video.load()
        }, 10)
      } else {
        throw new Error('err')
      }
    },
    newVideoURL (background) {
      // called whenever user clicks on menu button
      console.log('newVideoURL called')
      var vm = this
      vm.vid = ''
      vm.url = ''
      // vm.generatedVideos = []
      vm.currentBackground = background
      vm.twitterAuthStatus = ''
      var theme = ''
      theme = vm.themes[background]
      vm.currentTheme = theme.imageid
      vm.state = 'Pause'
      vm.loading = true
      vm.generatedVideos[background] = 'getting'
      // TODO: Language
      // if (vm.$parent.podcastList[vm.$parent.podId]['language'] && vm.$parent.podcastList[vm.$parent.podId]['language'].indexOf('en') < 0) {
      //   console.log(vm.$parent.podcastList[vm.$parent.podId]['language'])
      //   theme['font'] = 'noto-sans'
      // }
      // theme['font'] = 'noto-sans'
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      var postCall = {}
      postCall['userid'] = vm.uid
      postCall['start'] = vm.words[0][1]
      postCall['end'] = vm.words[vm.words.length - 1][2]
      postCall['podid'] = vm.podId
      postCall['words'] = vm.words
      postCall['theme'] = theme
      postCall['uid'] = vm.currentRequest
      console.log(postCall)
      vm.requestBackend(CONSTANTS.NEW_VIDEO_GENERATE_URL, postCall, theme.imageid)
    }
  }
}
</script>

<style lang="css" scoped>
.bold{
  font-weight: bold;
}
.whiteText{
  color:white;
  cursor:pointer;
}
.underline{
  text-decoration:underline;
}
a:hover{
  color:white;
  text-decoration: underline !important;
}
#uploadFile{
  display:none;
  position: absolute;
  left: -9999px;
}
.ui.inverted.basic.segment{
  margin-top: 0px;
}
.borderAll{
  border: #5A29A3;
  border-width: 1px;
  border-style: solid;
}
::-webkit-input-placeholder {
  color: rgba(255,255,255,0.15)!important;
}
#vjs_video_3{
  width: 100%;
}
.video-js.vjs-paused.vjs_video_1514-dimensions.vjs-controls-enabled.vjs-workinghover.vjs-v6.vjs-user-active{
  width: 540px;
  height: 304px;
}
.vjs-tech{
  width: 540px !important;
  height: 304px !important;
}
.video-js .vjs-tech{
  width: 540px;
  height: 304px;
}
.imageCube{
  border-radius: 4px;
  height: 20px;
  margin: 4px;
  width: 20px;
  cursor: pointer;
}
.playColor{
  background-color: #4BDACC !important;
  color:white !important;
}
.pauseColor{
  background-color: #324851 !important;
}
.redText{
  color:#F0665C;
}
.tealText{
  color:#4BDACC;
}
.imageCube.active{
  box-shadow:0 0 3pt 2pt white
}
</style>
